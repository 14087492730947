import React from "react";
import Rails from '@rails/ujs';
import 'bootstrap';

import I18n from "i18n-js";

import importComponent from '../components';

import * as routes from '../routes';

import translations from '../locales.json';
I18n.translations = translations;
I18n.locale = 'en';
// const i18n = new I18n(translations);

// let componentsContext = {}
// Components.forEach((component) => {
//   componentsContext[component.name.replace(".js.jsx", "")] = component.module.default
// })

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// Rails.start();

window.importComponent = importComponent;
window.I18n = I18n;
window.$ = window.jQuery = require('jquery');
window.Routes = routes;
window.Popper = require('popper.js');
// import Turbolinks from 'turbolinks';
// import * as ActiveStorage from '@rails/activestorage';
// import 'channels';

Rails.start();
// Turbolinks.start();
// ActiveStorage.start();
